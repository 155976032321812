:root {
  color-scheme: light dark; /* 1 */
  --color-solid: black;
  --color-solid-middle: #444;
  --color-middle: #888;
  --color-faint: #ddd;
  --color-fainter: #eee;
  --color-opposite: white;
  --color-warning: #fa0;

  --color-bg: var(--color-opposite);
  --color-rule-first: var(--color-faint);
  --color-rule: var(--color-fainter);
  --color-prog-bar-thread: var(--color-solid);
  --color-prog-bar-track: var(--color-rule-first);
  --color-text: var(--color-solid);
  --color-text-subdued: var(--color-solid-middle);
  --color-text-subdued-more: var(--color-middle);

  /**
   * 1. Not sure why this `color-scheme` property is needed
   *    since dark mode switching seems to work fine without it,
   *    but added per
   *    https://webkit.org/blog/8840/dark-mode-support-in-webkit/.
   */
}

@media (prefers-color-scheme: dark) {
  :root {
    --color-solid: #ddd;
    --color-solid-middle: #999;
    --color-middle: #666;
    --color-faint: #333;
    --color-fainter: #222;
    --color-opposite: black;
    --color-warning: #fc0;
  }
}

body {
  color: var(--color-text);
  background-color: var(--color-bg);
}

.App {
  font-size: 18px;
  animation: fadeInAnimation ease-out 0.5s;
  animation-iteration-count: 1;
  animation-fill-mode: forwards;
}

@media screen and (min-width: 321px) {
  .App {
    font-size: 21px;
  }
}

@keyframes fadeInAnimation {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

.spinner {
  margin-top: 8rem;
  text-align: center;
}

.error {
  line-height: 1.5;
  text-align: center;
  margin: 4rem 2rem;
}

.error h1 {
  font-size: inherit;
  margin-bottom: 0;
}

.error h1 span {
  font-size: 48px;
  display: block;
}

.error p {
  margin-top: 0;
}

table {
  margin: 2rem auto;
  table-layout: fixed;
  text-align: left;
  border-spacing: 0;
}

thead th {
  font-weight: normal;
  font-size: 0.625em;
}

thead th span {
  float: right;
}

th,
td {
  padding: 0.875rem 0 1rem;
  border-bottom: 1px solid var(--color-rule);
}

tbody tr:first-child td {
  border-bottom-color: var(--color-rule-first);
}

tbody th {
  flex: 1 0 auto;
  display: flex;
  align-items: baseline;
  font-weight: normal;
}

.deemphasize {
  color: var(--color-text-subdued-more);
}

.deemphasize th {
  font-weight: normal;
}

.units {
  font-size: 0.625em;
  margin-right: 0.35rem;
}

.note {
  /* margin-left: auto; */
  padding: 0 1.5rem 0 0.5rem;
  font-size: 0.625em;
  font-weight: normal;
  color: var(--color-text-subdued-more);
}

.hour-count {
  text-align: right;
  font-variant-numeric: tabular-nums;
}

.progress-bar {
  margin-top: -1rem;
}

.progress-bar .track {
  width: 100%;
  height: 0.5rem;
  background-color: var(--color-prog-bar-track);
}

.progress-bar .progress {
  background-color: var(--color-prog-bar-thread);
  height: 0.5rem;
}

.progress-bar .progress.complete {
  background-color: var(--color-warning);
}

.progress-bar p {
  margin: 0.75rem 0 0;
  padding: 0;
  font-size: 0.625em;
  text-align: center;
  color: var(--color-text-subdued);
}
